import React from 'react';
import sobre from '../images/sobre.jpeg';
import iconEmail from '../images/email_icon.png';

class Sobre extends React.Component {
  render() {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };
    return (
      <div>
        <div
          id="sobre"
          className="flex ml-10 mr-10 mt-10 justify-center w-[90%] pb-10"
        >
          <div
            className="flex flex-col lg:w-[40%]"
          >
            <p
              className="font-custom text-5xl mb-10 text-left lg:pl-10"
            >
              SOBRE A TONCAR
            </p>
            <p
              className="w-[100%]
            lg:w-[90%]
            xl:w-[90%]
            text-xl text-left font-thin lg:pl-10"
            >
              Fundada em 1995, nossa empresa de auto peças é especializada em
              fornecer peças de qualidade para veículos de todas as marcas
              e modelos. Temos uma ampla variedade de produtos, desde peças
              básicas até peças de alto desempenho para carros de corrida. Nossos
              funcionários são altamente treinados e dedicados a fornecer o melhor
              atendimento ao cliente possível.
            </p>
          </div>
          <img
            className="hidden lg:flex w-[50%] h-[50%] bg-black"
            src={ sobre }
            alt="sobre"
          />
        </div>
        <div
          className="flex justify-center py-2"
        >
          <button
            onClick={ () => scrollToSection('home') }
            className="flex bg-orange-600
            w-[120px] h-[40px] md:w-[150px] md:h=[50px]
          items-center justify-center text-white rounded-xl text-sm lg:text-lg"
          >
            Voltar ao início
          </button>
        </div>
        <div
          className="text-white bg-gray-950 h-2 text-xs"
        >
          <div
            className="flex justify-center items-center bg-slate-950"
          >
            <img
              src={ iconEmail }
              className="w-10 h-8"
              alt="icon-email"
            />
            <div
              className="flex h-10 items-center"
            >
              Email para contato
            </div>
          </div>
          <p
            className="mb-4 h-10 bg-black"
          >
            atendimento@autopecastoncar.com.br
          </p>
        </div>
      </div>
    );
  }
}

export default Sobre;

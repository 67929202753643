import React from 'react';
import logo from '../images/logo.png';
import List from './List';

class Header extends React.Component {
  render() {
    const scrollToSection = (sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };
    return (
      <header
        className="flex justify-between h-[80px] items-center bg-black
      sm:h[80px] lg:h-[130px] w-full fixed"
      >
        <img
          className="pl-10 pt-4 h-[80px] lg:w-[350px] lg:h-[130px]"
          src={ logo }
          alt="logo"
        />
        <div
          className="md:flex lg:ml-80
          w-[700px] justify-between h-[50px] items-center hidden"
        >
          <button
            className="w-full text-gray-50 hover:text-orange-400 cursor-pointer
            hover:text-xl"
            onClick={ () => scrollToSection('home') }
          >
            Home
          </button>
          <button
            className="w-full text-gray-50 hover:text-orange-400 cursor-pointer
            hover:text-xl"
            onClick={ () => scrollToSection('produtos') }
          >
            Produtos
          </button>
          <button
            className="w-full text-gray-50 hover:text-orange-400 cursor-pointer
            hover:text-xl"
            onClick={ () => scrollToSection('sobre') }
          >
            Sobre
          </button>
          <button
            id="whats"
            className="w-full text-gray-50 hover:text-orange-400 cursor-pointer
            hover:text-xl"
            onClick={ () => window.open(
              'https://api.whatsapp.com/send?phone=551151083625&text=Ol%C3%A1,%20estou%20interessado%20em%20pe%C3%A7as%20automotivas!',
            ) }
          >
            Fale Conosco
          </button>
        </div>
        <div className="dropdown">
          <button
            className=""
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <List />
          </button>
          <ul className="dropdown-menu flex">
            <li>
              <button
                className="dropdown-item h-[50px]"
                type="button"
                onClick={ () => scrollToSection('home') }
              >
                Home
              </button>
            </li>
            <li>
              <button
                className="dropdown-item h-[50px]"
                type="button"
                onClick={ () => scrollToSection('produtos') }
              >
                Produtos
              </button>
            </li>
            <li>
              <button
                className="dropdown-item h-[50px]"
                type="button"
                onClick={ () => scrollToSection('sobre') }
              >
                Sobre
              </button>
            </li>
            <li>
              <button
                id="whats"
                className="dropdown-item h-[50px]"
                type="button"
                onClick={ () => window.open(
                  'https://api.whatsapp.com/send?phone=551151083625&text=Ol%C3%A1,%20estou%20interessado%20em%20pe%C3%A7as%20automotivas!',
                ) }
              >
                Fale Conosco
              </button>
            </li>
          </ul>
        </div>
      </header>
    );
  }
}

export default Header;
